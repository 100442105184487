import { Order } from '../types';

export const formatDate = (dateString?: string): string => {
  if (!dateString) return 'N/A';
  
  const date = new Date(dateString);
  if (isNaN(date.getTime())) {
    return 'Invalid Date';
  }
  
  return date.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric'
  });
};

export const formatCurrency = (amount: number | null | undefined, currencyCode: string | null | undefined): string => {
  if (amount === null || amount === undefined || !currencyCode || amount === 0) return 'N/A';
  return new Intl.NumberFormat('en-US', { 
    style: 'currency', 
    currency: currencyCode 
  }).format(amount);
};

export const getReviewStatusColor = (status: string): "success" | "warning" | "error" | "default" => {
  switch (status) {
    case 'REQUESTED':
      return 'success';
    case 'PENDING':
      return 'warning';
    case 'FAILED':
      return 'error';
    default:
      return 'default';
  }
};

export const formatOrderTotal = (order: Order) => {
  if (!order.orderTotal || !order.currencyCode) return 'N/A';
  
  const amount = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: order.currencyCode || 'USD'
  }).format(order.orderTotal || 0);
  
  return order.isEstimatedTotal === true ? `${amount}*` : amount;
}; 