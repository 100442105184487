import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { User, Order } from '../types';
import { supabase } from '../config/supabase';

class ApiService {
  private api: AxiosInstance;
  private cache: Map<string, { data: any; timestamp: number }>;
  private readonly CACHE_DURATION = 30000; // 30 seconds cache
  private readonly PUBLIC_ENDPOINTS = [
    '/users/register',
    '/users/login',
    '/users/verify-email'
  ];

  constructor() {
    this.api = axios.create({
      baseURL: process.env.REACT_APP_API_URL || 'http://localhost:3001/api',
      timeout: 5000,
    });
    this.cache = new Map();
    this.setupInterceptors();
  }

  private setupInterceptors() {
    this.api.interceptors.request.use(async (config) => {
      console.log('API Request:', config.url);
      
      const isPublicEndpoint = this.PUBLIC_ENDPOINTS.some(
        endpoint => config.url?.endsWith(endpoint)
      );
      
      if (isPublicEndpoint) {
        console.log('Public endpoint, skipping auth');
        return config;
      }

      try {
        const { data: { session } } = await supabase.auth.getSession();
        console.log('Got session for request:', !!session);
        
        if (!session?.access_token) {
          console.error('No access token in session');
          throw new Error('No valid session');
        }
        
        config.headers.Authorization = `Bearer ${session.access_token}`;
        return config;
      } catch (error) {
        console.error('Auth error in interceptor:', error);
        return Promise.reject(new Error('Authentication required'));
      }
    });

    this.api.interceptors.response.use(
      (response) => {
        console.log('API Response:', response.config.url, response.status);
        return response;
      },
      async (error) => {
        console.error('API Error:', {
          url: error.config?.url,
          status: error.response?.status,
          message: error.message
        });
        
        if (error.response?.status === 401) {
          this.clearCache();
          const { data: { session } } = await supabase.auth.getSession();
          if (!session) {
            await supabase.auth.signOut();
          }
        }
        return Promise.reject(error);
      }
    );
  }

  private getCacheKey(endpoint: string): string {
    return `${endpoint}`;
  }

  private getCachedData<T>(key: string): T | null {
    const cached = this.cache.get(key);
    if (!cached) return null;

    const now = Date.now();
    if (now - cached.timestamp > this.CACHE_DURATION) {
      this.cache.delete(key);
      return null;
    }

    return cached.data;
  }

  private setCachedData(key: string, data: any): void {
    this.cache.set(key, {
      data,
      timestamp: Date.now()
    });
  }

  // User endpoints
  async getCurrentUser(config?: AxiosRequestConfig): Promise<{ data: User }> {
    try {
      console.log('ApiService: Getting current user');
      console.log('ApiService: Using baseURL:', this.api.defaults.baseURL); // Debug baseURL

      const cacheKey = this.getCacheKey('/users/me');
      const cached = this.getCachedData<User>(cacheKey);
      if (cached) {
        console.log('ApiService: Returning cached user data');
        return { data: cached };
      }

      // Add timeout to this specific request if not provided
      const requestConfig = {
        ...config,
        timeout: 5000,
      };

      try {
        const response = await this.api.get<User>('/users/me', requestConfig);
        console.log('ApiService: Got user response:', response.data);
        this.setCachedData(cacheKey, response.data);
        return { data: response.data };
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.code === 'ECONNABORTED') {
            console.error('ApiService: Request timeout');
            throw new Error('Unable to connect to the server. Please check if the backend is running.');
          }
          if (!error.response) {
            console.error('ApiService: Network error');
            throw new Error('Network error. Please check your connection and if the backend is running.');
          }
          console.error('ApiService: HTTP error:', error.response.status);
          throw error;
        }
        throw error;
      }
    } catch (error) {
      console.error('ApiService: Error getting current user:', error);
      throw error;
    }
  }

  async getAmazonAuthUrl(): Promise<AxiosResponse<{ url: string }>> {
    return this.api.get<{ url: string }>('/users/amazon-auth-url');
  }

  // Orders endpoints
  async getOrders(config?: AxiosRequestConfig): Promise<{ data: Order[] }> {
    const cacheKey = this.getCacheKey('/orders');
    const cached = this.getCachedData<Order[]>(cacheKey);
    if (cached) return { data: cached };

    const response = await this.api.get<Order[]>('/orders', config);
    this.setCachedData(cacheKey, response.data);
    return { data: response.data };
  }

  async getOrdersMetrics(timeframe: '7d' | '30d' | '90d' = '30d'): Promise<{ data: any }> {
    const cacheKey = this.getCacheKey(`/orders/metrics?timeframe=${timeframe}`);
    const cached = this.getCachedData(cacheKey);
    if (cached) return { data: cached };

    const response = await this.api.get('/orders/metrics', {
      params: { timeframe }
    });
    this.setCachedData(cacheKey, response.data);
    return { data: response.data };
  }

  async syncOrders(): Promise<AxiosResponse<{ jobId: string; status: string }>> {
    return this.api.post('/orders/sync');
  }

  async requestFeedback(orderId: string) {
    return this.api.post(`/orders/${orderId}/request-feedback`);
  }

  // Add these methods if they don't exist
  async login(email: string, password: string) {
    return this.api.post('/users/login', { email, password });
  }

  async register(userData: { id: string; email: string }) {
    try {
      const response = await this.api.post('/users/register', userData);
      // Don't cache or set any auth state here
      return response;
    } catch (error) {
      // If it's a 409 (Conflict) error, the user already exists - this is OK
      if (axios.isAxiosError(error) && error.response?.status === 409) {
        return { data: { message: 'User profile already exists' } };
      }
      throw error;
    }
  }

  // Add this method to the ApiService class
  async getSyncProgress(jobId: string) {
    return this.api.get(`/orders/sync/${jobId}`);
  }

  // Clear cache method
  clearCache(): void {
    this.cache.clear();
  }

  // Method to invalidate specific cache entries
  invalidateCache(endpoint: string): void {
    const key = this.getCacheKey(endpoint);
    this.cache.delete(key);
  }

  // Add method to check auth state
  async isAuthenticated(): Promise<boolean> {
    const { data: { session } } = await supabase.auth.getSession();
    return !!session?.access_token;
  }
}

export const apiService = new ApiService(); 