import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Paper,
  Typography,
  Button,
  Box,
  Alert,
  Link,
  Chip,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import {
  ShoppingCart,
  Sync,
  Feedback,
} from '@mui/icons-material';
import { 
  DataGrid, 
  GridColDef, 
  GridRenderCellParams 
} from '@mui/x-data-grid';
import { apiService } from '../services/api.service';
import { formatDate, formatCurrency, getReviewStatusColor } from '../utils';
import { Order } from '../types';
import { supabase } from '../config/supabase';

const getAmazonOrderUrl = (orderId: string): string => {
  return `https://sellercentral.amazon.com/orders-v3/order/${orderId}`;
};

const getItems = (order: Order): string => {
  if (!order.items?.length) return 'N/A';
  return order.items.map(item => 
    `${item.title} (${item.quantity})`
  ).join(', ');
};

const getSKUs = (order: Order): string => {
  if (!order.items?.length) return 'N/A';
  return order.items.map(item => item.sku).join(', ');
};

const canRequestReview = (order: Order): boolean => {
  const shippedStatuses = ['Shipped', 'Delivered'];
  return shippedStatuses.includes(order.orderStatus) && 
         order.reviewRequestStatus !== 'REQUESTED';
};

interface SyncProgress {
  jobId: string;
  status: 'waiting' | 'active' | 'completed' | 'failed';
  progress: number;
  result?: {
    totalOrders: number;
    processedOrders: number;
  };
  error?: string;
}

const SyncProgressDialog: React.FC<{
  open: boolean;
  progress: number;
  processedOrders?: number;
  totalOrders?: number;
  isInitialSync?: boolean;
  stage?: 'counting' | 'processing' | 'completed';
}> = React.memo(({ open, progress, processedOrders, totalOrders, isInitialSync, stage }) => (
  <Dialog 
    open={open} 
    maxWidth="sm" 
    fullWidth
    disableEscapeKeyDown
    onClose={() => {}}
  >
    <DialogTitle>
      {isInitialSync ? 'Initial Order Sync' : 'Syncing Orders'}
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        {stage === 'counting' ? (
          `Counting orders to sync... Found ${totalOrders || 0} orders so far...`
        ) : stage === 'processing' ? (
          `Processing ${processedOrders || 0} of ${totalOrders || 0} orders...`
        ) : (
          'Preparing to sync orders...'
        )}
      </DialogContentText>
      <Box sx={{ mt: 2 }}>
        <LinearProgress 
          variant="determinate" 
          value={progress}
          sx={{ height: 10, borderRadius: 5 }}
        />
        <Typography 
          variant="body2" 
          color="text.secondary"
          align="center"
          sx={{ mt: 1 }}
        >
          {Math.round(progress)}%
        </Typography>
      </Box>
    </DialogContent>
  </Dialog>
));

const Orders: React.FC = () => {
  const navigate = useNavigate();
  const [orders, setOrders] = useState<Order[]>([]);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [isSyncing, setIsSyncing] = useState(false);
  const [syncState, setSyncState] = useState<{
    isActive: boolean;
    jobId?: string;
    progress: number;
    processedOrders?: number;
    totalOrders?: number;
    stage?: 'counting' | 'processing' | 'completed';
  }>({
    isActive: false,
    progress: 0
  });

  const fetchOrders = useCallback(async () => {
    try {
      const { data: { session } } = await supabase.auth.getSession();
      if (!session) {
        navigate('/auth', { replace: true });
        return;
      }

      const response = await apiService.getOrders();
      setOrders(response.data);
      setError('');
    } catch (err: any) {
      console.error('Error fetching orders:', err);
      if (!(err instanceof Error && err.name === 'AbortError')) {
        setError('Failed to fetch orders');
        if (err.response?.status === 401) {
          navigate('/auth', { replace: true });
        }
      }
    }
  }, [navigate]);

  useEffect(() => {
    const controller = new AbortController();
    let retryTimeout: NodeJS.Timeout;

    const loadOrders = async () => {
      try {
        const { data: { session } } = await supabase.auth.getSession();
        if (!session) {
          retryTimeout = setTimeout(loadOrders, 1000);
          return;
        }

        const response = await apiService.getOrders({ signal: controller.signal });
        setOrders(response.data);
        setError('');
      } catch (err: any) {
        if (!(err instanceof Error && err.name === 'AbortError')) {
          console.error('Error loading orders:', err);
          if (err.response?.status === 401) {
            navigate('/auth', { replace: true });
          } else {
            retryTimeout = setTimeout(loadOrders, 2000);
          }
        }
      }
    };

    loadOrders();

    return () => {
      controller.abort();
      if (retryTimeout) clearTimeout(retryTimeout);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const pollSyncProgress = useCallback(async (jobId: string) => {
    try {
      const response = await apiService.getSyncProgress(jobId);
      const progress = response.data;

      console.log('Sync progress update:', progress);

      setSyncState(prev => ({
        ...prev,
        progress: progress.progress || 0,
        processedOrders: progress.result?.processedOrders || 0,
        totalOrders: progress.result?.totalOrders || 0,
        isInitialSync: progress.result?.isInitialSync,
        stage: progress.stage
      }));

      if (progress.status === 'completed' || progress.status === 'failed') {
        if (progress.status === 'completed') {
          const result = progress.result;
          setMessage(
            result?.isInitialSync
              ? `Initial sync complete! Processed ${result.totalOrders} orders from the last 2 days ` +
                `(${result.newOrders} new, ${result.updatedOrders} updated)`
              : `Sync complete! Processed ${result.totalOrders} orders since last sync ` +
                `(${result.newOrders} new, ${result.updatedOrders} updated)`
          );
          await fetchOrders();
        } else if (progress.status === 'failed') {
          setError(progress.error || 'Sync failed');
        }
        
        setTimeout(() => {
          setSyncState({ isActive: false, progress: 0 });
        }, 2000);
        
        return;
      }

      setTimeout(() => pollSyncProgress(jobId), 2000);
    } catch (err) {
      console.error('Error polling sync progress:', err);
      setError('Failed to get sync progress');
      setSyncState({ isActive: false, progress: 0 });
    }
  }, [fetchOrders]);

  const syncOrders = async () => {
    try {
      setIsSyncing(true);
      setError('');
      setMessage('');
      
      const response = await apiService.syncOrders();
      const { jobId } = response.data;
      
      setSyncState({
        isActive: true,
        jobId,
        progress: 0
      });
      
      pollSyncProgress(jobId);
    } catch (err: any) {
      setError(err.response?.data?.message || 'Failed to sync orders');
      if (err.response?.status === 401) {
        navigate('/auth', { replace: true });
      }
    } finally {
      setIsSyncing(false);
    }
  };

  const requestFeedback = async (orderId: string) => {
    try {
      setError('');
      setMessage('');

      const response = await apiService.requestFeedback(orderId);
      setMessage(response.data.message);

      await fetchOrders();
    } catch (err: any) {
      setError(err.response?.data?.message || 'Failed to request feedback');
      if (err.response?.status === 401) {
        navigate('/auth', { replace: true });
      }
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'purchaseDate',
      headerName: 'Date',
      width: 100,
      renderCell: (params: GridRenderCellParams<Order>) => 
        formatDate(params.row.purchaseDate)
    },
    {
      field: 'amazonOrderId',
      headerName: 'Order ID',
      width: 160,
      renderCell: (params: GridRenderCellParams<Order>) => (
        <Link
          href={getAmazonOrderUrl(params.row.amazonOrderId)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {params.row.amazonOrderId}
        </Link>
      )
    },
    {
      field: 'orderTotal',
      headerName: 'Total',
      width: 90,
      renderCell: (params: GridRenderCellParams<Order>) => 
        formatCurrency(params.row.orderTotal, params.row.currencyCode)
    },
    {
      field: 'totalItems',
      headerName: 'Qty',
      width: 60,
      align: 'center',
      headerAlign: 'center'
    },
    {
      field: 'items',
      headerName: 'Items',
      flex: 3,
      minWidth: 200,
      renderCell: (params: GridRenderCellParams<Order>) => (
        <Typography noWrap>
          {params.row.items?.map(item => 
            `${item.title} (${item.quantity})`
          ).join(', ') || 'N/A'}
        </Typography>
      )
    },
    {
      field: 'skus',
      headerName: 'SKUs',
      width: 120,
      renderCell: (params: GridRenderCellParams<Order>) => (
        <Typography noWrap>
          {params.row.items?.map(item => 
            item.sku
          ).join(', ') || 'N/A'}
        </Typography>
      )
    },
    {
      field: 'orderStatus',
      headerName: 'Status',
      width: 100,
      renderCell: (params: GridRenderCellParams<Order>) => (
        <Chip 
          label={params.row.orderStatus}
          color={params.row.orderStatus === 'Delivered' ? 'success' : 'default'}
          size="small"
        />
      )
    },
    {
      field: 'reviewRequestStatus',
      headerName: 'Review',
      width: 100,
      renderCell: (params: GridRenderCellParams<Order>) => (
        <Chip 
          label={params.row.reviewRequestStatus}
          color={getReviewStatusColor(params.row.reviewRequestStatus)}
          size="small"
        />
      )
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 120,
      sortable: false,
      renderCell: (params: GridRenderCellParams<Order>) => (
        <Button
          variant="contained"
          color="primary"
          size="small"
          startIcon={<Feedback />}
          onClick={() => requestFeedback(params.row.id)}
          disabled={!canRequestReview(params.row)}
        >
          Request
        </Button>
      )
    }
  ];

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Container 
        maxWidth="lg" 
        sx={{ 
          mt: 4, 
          mb: 4,
          px: 2
        }}
      >
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        {message && <Alert severity="success" sx={{ mb: 2 }}>{message}</Alert>}

        <Paper sx={{ 
          width: '100%', 
          height: 'calc(100vh - 180px)',
          overflow: 'hidden'
        }}>
          <Box sx={{ 
            px: 2,
            py: 2,
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'space-between'
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <ShoppingCart />
              <Typography variant="h5" component="h2">
                Orders
              </Typography>
            </Box>
            <Button
              variant="contained"
              color="primary"
              startIcon={<Sync />}
              onClick={syncOrders}
              disabled={isSyncing}
            >
              {isSyncing ? 'Syncing...' : 'Sync Orders'}
            </Button>
          </Box>
          
          <Box sx={{ 
            height: 'calc(100% - 72px)',
            width: '100%'
          }}>
            <DataGrid
              rows={orders}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 25, page: 0 },
                },
              }}
              pageSizeOptions={[10, 25, 50, 100]}
              checkboxSelection
              disableRowSelectionOnClick
              loading={isSyncing}
              getRowId={(row) => row.id}
              sx={{
                border: 'none',
                '& .MuiDataGrid-cell': {
                  borderBottom: '1px solid #e0e0e0',
                  px: 1
                },
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: 'background.paper',
                  borderBottom: '2px solid #e0e0e0',
                  px: 1
                },
                '& .MuiDataGrid-row:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)',
                },
                width: '100%',
                '& .MuiDataGrid-main': {
                  width: '100%',
                }
              }}
            />
          </Box>
        </Paper>
        
        <SyncProgressDialog 
          open={syncState.isActive}
          progress={syncState.progress}
          processedOrders={syncState.processedOrders}
          totalOrders={syncState.totalOrders}
          isInitialSync={syncState.isActive}
          stage={syncState.stage}
        />
      </Container>
    </Box>
  );
};

export default Orders; 