import React from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Star as StarIcon } from '@mui/icons-material';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    maxWidth: '700px',
    width: '100%',
    margin: theme.spacing(2),
    borderRadius: theme.spacing(2),
    padding: theme.spacing(4),
    backgroundColor: '#232F3E',
    color: 'white',
  },
}));

// Keyframes for the flowing dots animation
const flowingDotsKeyframes = `
  @keyframes flowDots {
    0% {
      transform: translateX(0);
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      transform: translateX(200px);
      opacity: 0;
    }
  }
`;

// Styled component for the flowing dots
const AnimatedSVG = styled('svg')`
  ${flowingDotsKeyframes}

  .dot {
    animation: flowDots 3s infinite;
  }

  .dot:nth-of-type(2) {
    animation-delay: 0.2s;
  }

  .dot:nth-of-type(3) {
    animation-delay: 0.4s;
  }

  .dot:nth-of-type(4) {
    animation-delay: 0.6s;
  }

  .dot:nth-of-type(5) {
    animation-delay: 0.8s;
  }
`;

interface Props {
  open: boolean;
}

const InitialSyncModal: React.FC<Props> = ({ open }) => {
  return (
    <StyledDialog
      open={open}
      maxWidth="md"
      disableEscapeKeyDown
      disableEnforceFocus={false}
      disablePortal={false}
    >
      <DialogContent>
        <Box sx={{ textAlign: 'center' }}>
          {/* Logo Flow Animation */}
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            gap: 4,
            mb: 6,
            mt: 2,
          }}>
            {/* Amazon Logo */}
            <Box
              sx={{
                width: 100,
                height: 100,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '20px',
                bgcolor: '#232F3E',
                overflow: 'hidden'
              }}
            >
              <svg viewBox="0 0 1000 1000" width="80" height="80">
                <path
                  d="M250 200 C250 200, 400 200, 500 200 C600 200, 700 200, 700 300 C700 400, 700 500, 600 600 C500 700, 400 700, 300 700 L250 700 L250 200"
                  fill="white"
                />
                <path
                  d="M100 750 C250 650, 750 650, 900 750 L950 700"
                  fill="#FF9900"
                  strokeLinecap="round"
                />
              </svg>
            </Box>

            {/* Flowing Dots Animation */}
            <Box sx={{ position: 'relative', width: '200px', height: '40px' }}>
              <AnimatedSVG width="200" height="40">
                <circle className="dot" cx="0" cy="20" r="4" fill="#FF9900" />
                <circle className="dot" cx="0" cy="20" r="4" fill="#FF9900" />
                <circle className="dot" cx="0" cy="20" r="4" fill="#FF9900" />
                <circle className="dot" cx="0" cy="20" r="4" fill="#FF9900" />
                <circle className="dot" cx="0" cy="20" r="4" fill="#FF9900" />
              </AnimatedSVG>
            </Box>

            {/* ReviewsMaxx Logo */}
            <Box
              sx={{
                width: 100,
                height: 100,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '20px',
                bgcolor: '#232F3E'
              }}
            >
              <StarIcon sx={{ 
                color: '#EAB308',
                fontSize: '4rem',
                strokeWidth: 2,
                stroke: 'currentColor',
                fill: 'none'
              }} />
            </Box>
          </Box>

          <Typography variant="h4" component="h2" fontWeight={700} gutterBottom sx={{ mb: 3, color: 'white' }}>
            Syncing Your Amazon Orders
          </Typography>
          
          <Typography variant="h6" sx={{ maxWidth: '600px', mx: 'auto', lineHeight: 1.6, color: 'rgba(255, 255, 255, 0.8)' }}>
            We're connecting to your Amazon account and syncing your orders. Depending on your account size and Amazon's response time, 
            this process may take up to 48 hours, though usually it's just a few hours. We'll email you when everything is ready!
          </Typography>
        </Box>
      </DialogContent>
    </StyledDialog>
  );
};

export default InitialSyncModal; 