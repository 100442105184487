import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Grid,
  Alert,
  CircularProgress,
  useTheme,
  Card,
  CardContent,
  Divider,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
  Legend,
} from 'recharts';
import { apiService } from '../services/api.service';
import { Order } from '../types';
import MetricCard from '../components/MetricCard';

interface DashboardMetrics {
  current: {
    orderCount: number;
    totalSales: number;
    reviewRequestsSent: number;
    reviewRequestsPending: number;
    reviewRequestsSuccess: number;
    reviewRequestsFailed: number;
    averageOrderValue: number;
  };
  previous: {
    orderCount: number;
    totalSales: number;
    reviewRequestsSent: number;
    averageOrderValue: number;
  };
  timeSeriesData: {
    date: string;
    orders: number;
    sales: number;
    reviewRequests: number;
  }[];
}

interface OrdersMetrics {
  today: {
    orders: number;
    totalAmount: number;
    averageOrderValue: number;
    ordersVsYesterday: number;
    amountVsYesterday: number;
  };
  timeSeriesData: {
    date: string;
    orders: number;
    totalAmount: number;
    averageOrderValue: number;
  }[];
  summary: {
    totalOrders: number;
    totalAmount: number;
    averageOrderValue: number;
    orderGrowth: number;
    amountGrowth: number;
  };
}

interface SkuMetrics {
  totalSales: number;
  averageDaily: number;
  trend: number;
  timeSeriesData: {
    date: string;
    sales: number;
  }[];
}

interface SkuInfo {
  sku: string;
  title: string;
}

const CHART_COLORS = [
  '#8884d8',
  '#82ca9d',
  '#ffc658',
  '#ff7300',
  '#0088fe',
  '#00c49f',
  '#ffbb28',
  '#ff8042'
];

const generateUniqueSkus = (timeSeriesData: any[]): SkuInfo[] => {
  const skuMap = new Map<string, string>();
  timeSeriesData.forEach(day => {
    if (day.skuDetails) {
      Object.entries(day.skuDetails).forEach(([sku, details]: [string, any]) => {
        if (!skuMap.has(sku)) {
          skuMap.set(sku, details.title || 'Unknown Product');
        }
      });
    }
  });
  return Array.from(skuMap.entries()).map(([sku, title]) => ({ sku, title }));
};

const formatTooltipValue = (value: number) => {
  return value ? `$${value.toFixed(2)}` : '$0.00';
};

const calculateSkuMetrics = (timeSeriesData: any[], sku: string): SkuMetrics => {
  const salesData = timeSeriesData.map(day => ({
    date: day.date,
    sales: day.salesBySku?.[sku] || 0
  }));

  const totalSales = salesData.reduce((sum, day) => sum + day.sales, 0);
  const averageDaily = totalSales / timeSeriesData.length;

  // Calculate trend (comparing last 7 days vs previous 7 days)
  const last7Days = salesData.slice(-7).reduce((sum, day) => sum + day.sales, 0);
  const previous7Days = salesData.slice(-14, -7).reduce((sum, day) => sum + day.sales, 0);
  const trend = previous7Days === 0 ? 0 : ((last7Days - previous7Days) / previous7Days) * 100;

  return {
    totalSales,
    averageDaily,
    trend,
    timeSeriesData: salesData
  };
};

const SkuMetricsCard: React.FC<{
  skuInfo: SkuInfo;
  metrics: SkuMetrics;
  color: string;
}> = ({ skuInfo, metrics, color }) => {
  const theme = useTheme();

  return (
    <Card>
      <CardContent>
        <Box sx={{ mb: 2 }}>
          <Typography variant="h6" sx={{ color: 'text.primary', mb: 0.5 }}>
            {skuInfo.sku}
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            {skuInfo.title}
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="body2" color="text.secondary">
                Total Sales
              </Typography>
              <Typography variant="h6">
                ${metrics.totalSales.toFixed(2)}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" color="text.secondary">
                Daily Average
              </Typography>
              <Typography variant="h6">
                ${metrics.averageDaily.toFixed(2)}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body2" color="text.secondary">
                7-Day Trend
              </Typography>
              <Typography 
                variant="h6" 
                sx={{ 
                  color: metrics.trend > 0 ? 'success.main' : metrics.trend < 0 ? 'error.main' : 'text.primary'
                }}
              >
                {metrics.trend > 0 ? '+' : ''}{metrics.trend.toFixed(1)}%
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ height: 200 }}>
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart data={metrics.timeSeriesData}>
              <defs>
                <linearGradient id={`gradient-${skuInfo.sku}`} x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor={color} stopOpacity={0.8}/>
                  <stop offset="95%" stopColor={color} stopOpacity={0.1}/>
                </linearGradient>
              </defs>
              <CartesianGrid 
                strokeDasharray="3 3" 
                vertical={false}
                stroke={theme.palette.divider}
              />
              <XAxis 
                dataKey="date" 
                stroke={theme.palette.text.secondary}
                tick={{ fill: theme.palette.text.secondary }}
              />
              <YAxis 
                stroke={theme.palette.text.secondary}
                tick={{ fill: theme.palette.text.secondary }}
                tickFormatter={(value) => `$${value}`}
              />
              <Tooltip
                contentStyle={{
                  backgroundColor: theme.palette.background.paper,
                  border: `1px solid ${theme.palette.divider}`,
                  borderRadius: theme.shape.borderRadius,
                }}
                formatter={formatTooltipValue}
              />
              <Area
                type="monotone"
                dataKey="sales"
                stroke={color}
                fill={`url(#gradient-${skuInfo.sku})`}
              />
            </AreaChart>
          </ResponsiveContainer>
        </Box>
      </CardContent>
    </Card>
  );
};

const Dashboard: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [metrics, setMetrics] = useState<DashboardMetrics | null>(null);
  const [ordersMetrics, setOrdersMetrics] = useState<OrdersMetrics | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [timeframe, setTimeframe] = useState<'7d' | '30d' | '90d'>('30d');

  const handleTimeframeChange = (event: React.MouseEvent<HTMLElement>, newTimeframe: '7d' | '30d' | '90d') => {
    if (newTimeframe !== null) {
      setTimeframe(newTimeframe);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [ordersResponse, metricsResponse] = await Promise.all([
          apiService.getOrdersMetrics(timeframe),
          apiService.getOrders()
        ]);
        
        const calculatedMetrics = calculateMetrics(metricsResponse.data);
        setMetrics(calculatedMetrics);
        setOrdersMetrics(ordersResponse.data);
      } catch (err: any) {
        setError('Failed to fetch dashboard data');
        if (err.response?.status === 401) {
          navigate('/auth', { replace: true });
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [timeframe]); // eslint-disable-line react-hooks/exhaustive-deps

  const calculateMetrics = (orders: Order[]): DashboardMetrics => {
    const now = new Date();
    const thirtyDaysAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
    const sixtyDaysAgo = new Date(now.getTime() - 60 * 24 * 60 * 60 * 1000);

    // Current period (last 30 days)
    const currentOrders = orders.filter(order => 
      new Date(order.purchaseDate) >= thirtyDaysAgo
    );

    // Previous period (30-60 days ago)
    const previousOrders = orders.filter(order => 
      new Date(order.purchaseDate) >= sixtyDaysAgo &&
      new Date(order.purchaseDate) < thirtyDaysAgo
    );

    const calculatePeriodMetrics = (periodOrders: Order[]) => {
      const totalSales = periodOrders.reduce((sum, order) => sum + (order.orderTotal || 0), 0);
      return {
        orderCount: periodOrders.length,
        totalSales,
        reviewRequestsSent: periodOrders.filter(o => o.reviewRequestStatus === 'REQUESTED').length,
        reviewRequestsPending: periodOrders.filter(o => o.reviewRequestStatus === 'PENDING').length,
        reviewRequestsSuccess: periodOrders.filter(o => 
          o.reviewRequestStatus === 'REQUESTED' && o.orderStatus === 'Delivered'
        ).length,
        reviewRequestsFailed: periodOrders.filter(o => o.reviewRequestStatus === 'FAILED').length,
        averageOrderValue: periodOrders.length ? totalSales / periodOrders.length : 0,
      };
    };

    // Calculate time series data
    const timeSeriesData = Array.from({ length: 30 }, (_, i) => {
      const date = new Date(now.getTime() - (29 - i) * 24 * 60 * 60 * 1000);
      const dayOrders = currentOrders.filter(order => 
        new Date(order.purchaseDate).toDateString() === date.toDateString()
      );

      return {
        date: date.toLocaleDateString('en-US', { month: 'short', day: '2-digit' }),
        orders: dayOrders.length,
        sales: dayOrders.reduce((sum, order) => sum + (order.orderTotal || 0), 0),
        reviewRequests: dayOrders.filter(o => o.reviewRequestStatus === 'REQUESTED').length
      };
    });

    return {
      current: calculatePeriodMetrics(currentOrders),
      previous: calculatePeriodMetrics(previousOrders),
      timeSeriesData,
    };
  };

  const calculateTrend = (current: number, previous: number): number => {
    if (previous === 0) return 0;
    return Math.round(((current - previous) / previous) * 100);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '400px' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!metrics || !ordersMetrics) return null;

  return (
    <Box sx={{ p: 3 }}>
      {error && <Alert severity="error" sx={{ mb: 3 }}>{error}</Alert>}

      <Grid container spacing={3}>
        {/* Today's Metrics */}
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ mb: 2, fontWeight: 600 }}>
            Today's Performance
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard
            title="Today's Sales"
            value={ordersMetrics?.today.totalAmount || 0}
            trend={ordersMetrics?.today.amountVsYesterday}
            trendLabel="vs yesterday"
            isCurrency
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard
            title="Today's Orders"
            value={ordersMetrics?.today.orders || 0}
            trend={ordersMetrics?.today.ordersVsYesterday}
            trendLabel="vs yesterday"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard
            title="Average Order Value"
            value={ordersMetrics?.today.averageOrderValue || 0}
            trend={calculateTrend(
              ordersMetrics?.today.averageOrderValue || 0,
              ordersMetrics?.today.averageOrderValue || 0
            )}
            trendLabel="vs yesterday"
            isCurrency
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard
            title="Review Requests"
            value={metrics.current.reviewRequestsSent}
            trend={calculateTrend(metrics.current.reviewRequestsSent, metrics.previous.reviewRequestsSent)}
            trendLabel="vs last period"
          />
        </Grid>

        {/* Period Summary */}
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ mb: 2, mt: 2, fontWeight: 600 }}>
            Period Summary
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard
            title="Total Sales"
            value={metrics.current.totalSales}
            trend={calculateTrend(metrics.current.totalSales, metrics.previous.totalSales)}
            trendLabel="vs last period"
            isCurrency
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard
            title="Total Orders"
            value={metrics.current.orderCount}
            trend={calculateTrend(metrics.current.orderCount, metrics.previous.orderCount)}
            trendLabel="vs last period"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard
            title="Review Requests"
            value={metrics.current.reviewRequestsSent}
            trend={calculateTrend(metrics.current.reviewRequestsSent, metrics.previous.reviewRequestsSent)}
            trendLabel="vs last period"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard
            title="Average Order Value"
            value={metrics.current.averageOrderValue}
            trend={calculateTrend(metrics.current.averageOrderValue, metrics.previous.averageOrderValue)}
            trendLabel="vs last period"
            isCurrency
          />
        </Grid>

        {/* Orders Over Time Chart */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Typography variant="h6" sx={{ color: 'text.primary' }}>
                  Orders Over Time
                </Typography>
                <ToggleButtonGroup
                  value={timeframe}
                  exclusive
                  onChange={handleTimeframeChange}
                  size="small"
                >
                  <ToggleButton value="7d">7D</ToggleButton>
                  <ToggleButton value="30d">30D</ToggleButton>
                  <ToggleButton value="90d">90D</ToggleButton>
                </ToggleButtonGroup>
              </Box>
              <Box sx={{ height: 400 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={ordersMetrics.timeSeriesData}>
                    <defs>
                      <linearGradient id="ordersGradient" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="5%" stopColor={theme.palette.primary.main} stopOpacity={0.1}/>
                        <stop offset="95%" stopColor={theme.palette.primary.main} stopOpacity={0}/>
                      </linearGradient>
                    </defs>
                    <CartesianGrid 
                      strokeDasharray="3 3" 
                      vertical={false}
                      stroke={theme.palette.divider}
                    />
                    <XAxis 
                      dataKey="date" 
                      stroke={theme.palette.text.secondary}
                      tick={{ fill: theme.palette.text.secondary }}
                    />
                    <YAxis 
                      yAxisId="left"
                      stroke={theme.palette.text.secondary}
                      tick={{ fill: theme.palette.text.secondary }}
                    />
                    <YAxis 
                      yAxisId="right" 
                      orientation="right"
                      stroke={theme.palette.text.secondary}
                      tick={{ fill: theme.palette.text.secondary }}
                      tickFormatter={(value) => `$${value}`}
                    />
                    <Tooltip
                      contentStyle={{
                        backgroundColor: theme.palette.background.paper,
                        border: `1px solid ${theme.palette.divider}`,
                        borderRadius: theme.shape.borderRadius,
                      }}
                    />
                    <Line
                      yAxisId="left"
                      type="monotone"
                      dataKey="orders"
                      name="Orders"
                      stroke={theme.palette.primary.main}
                      strokeWidth={2}
                      dot={false}
                    />
                    <Line
                      yAxisId="right"
                      type="monotone"
                      dataKey="totalAmount"
                      name="Total Amount"
                      stroke={theme.palette.secondary.main}
                      strokeWidth={2}
                      dot={false}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Sales by SKU */}
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                <Typography variant="h6" sx={{ color: 'text.primary' }}>
                  Sales by SKU
                </Typography>
                <ToggleButtonGroup
                  value={timeframe}
                  exclusive
                  onChange={handleTimeframeChange}
                  size="small"
                >
                  <ToggleButton value="7d">7D</ToggleButton>
                  <ToggleButton value="30d">30D</ToggleButton>
                  <ToggleButton value="90d">90D</ToggleButton>
                </ToggleButtonGroup>
              </Box>
              <Box sx={{ height: 400 }}>
                <ResponsiveContainer width="100%" height="100%">
                  <AreaChart data={ordersMetrics?.timeSeriesData || []}>
                    <defs>
                      {generateUniqueSkus(ordersMetrics?.timeSeriesData || []).map((skuInfo, index) => (
                        <linearGradient key={skuInfo.sku} id={`gradient-${skuInfo.sku}`} x1="0" y1="0" x2="0" y2="1">
                          <stop
                            offset="5%"
                            stopColor={CHART_COLORS[index % CHART_COLORS.length]}
                            stopOpacity={0.8}
                          />
                          <stop
                            offset="95%"
                            stopColor={CHART_COLORS[index % CHART_COLORS.length]}
                            stopOpacity={0.1}
                          />
                        </linearGradient>
                      ))}
                    </defs>
                    <CartesianGrid 
                      strokeDasharray="3 3" 
                      vertical={false}
                      stroke={theme.palette.divider}
                    />
                    <XAxis 
                      dataKey="date" 
                      stroke={theme.palette.text.secondary}
                      tick={{ fill: theme.palette.text.secondary }}
                    />
                    <YAxis 
                      stroke={theme.palette.text.secondary}
                      tick={{ fill: theme.palette.text.secondary }}
                      tickFormatter={(value) => `$${value}`}
                    />
                    <Tooltip
                      contentStyle={{
                        backgroundColor: theme.palette.background.paper,
                        border: `1px solid ${theme.palette.divider}`,
                        borderRadius: theme.shape.borderRadius,
                      }}
                      formatter={formatTooltipValue}
                    />
                    {generateUniqueSkus(ordersMetrics?.timeSeriesData || []).map((skuInfo, index) => (
                      <Area
                        key={skuInfo.sku}
                        type="monotone"
                        dataKey={(data) => data.salesBySku?.[skuInfo.sku] || 0}
                        name={skuInfo.sku}
                        stackId="1"
                        stroke={CHART_COLORS[index % CHART_COLORS.length]}
                        fill={`url(#gradient-${skuInfo.sku})`}
                      />
                    ))}
                  </AreaChart>
                </ResponsiveContainer>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h5" sx={{ mb: 2, mt: 2, fontWeight: 600 }}>
            SKU Performance
          </Typography>
        </Grid>
        {generateUniqueSkus(ordersMetrics?.timeSeriesData || [])
          .map(skuInfo => ({
            skuInfo,
            metrics: calculateSkuMetrics(ordersMetrics?.timeSeriesData || [], skuInfo.sku)
          }))
          .sort((a, b) => b.metrics.totalSales - a.metrics.totalSales)
          .map(({ skuInfo, metrics }, index) => (
            <Grid item xs={12} md={6} key={skuInfo.sku}>
              <SkuMetricsCard
                skuInfo={skuInfo}
                metrics={metrics}
                color={CHART_COLORS[index % CHART_COLORS.length]}
              />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default Dashboard;
