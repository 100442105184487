import React from 'react';
import {
  Dialog,
  DialogContent,
  Typography,
  Box,
  Button,
  Step,
  Stepper,
  StepLabel,
  Paper,
  Alert,
} from '@mui/material';
import { Star as StarIcon, Security as SecurityIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    maxWidth: '600px',
    width: '100%',
    margin: theme.spacing(2),
    borderRadius: theme.spacing(2),
  },
}));

interface Props {
  open: boolean;
  onConnect: () => void;
  activeStep: number;
  error?: string;
}

const AmazonConnectionModal: React.FC<Props> = ({ open, onConnect, activeStep, error }) => {
  const steps = [
    'Create Account',
    'SMS Verification',
    'Connect Amazon'
  ];

  return (
    <StyledDialog
      open={open}
      maxWidth="md"
      disableEscapeKeyDown
      disableEnforceFocus={false}
      disablePortal={false}
      aria-labelledby="amazon-connection-modal"
    >
      <DialogContent sx={{ p: 4 }}>
        <Box sx={{ mb: 4 }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        <Box sx={{ textAlign: 'center', mb: 4 }}>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mb: 3 }}>
            <Box
              sx={{
                width: 48,
                height: 48,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                bgcolor: 'primary.main',
                borderRadius: '12px',
              }}
            >
              <StarIcon sx={{ color: 'white', fontSize: '2rem' }} />
            </Box>
            <Typography variant="h5" sx={{ color: 'grey.500', mt: 1 }}>→</Typography>
            <Box
              sx={{
                width: 48,
                height: 48,
                borderRadius: '12px',
                bgcolor: '#232F3E',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <svg
                viewBox="0 0 24 24"
                width="32"
                height="32"
                fill="#FF9900"
              >
                <path d="M18.42,14.29a10.41,10.41,0,0,1-3.89.73,10.17,10.17,0,0,1-6.88-2.63.37.37,0,0,0-.53,0,.38.38,0,0,0,0,.54,11,11,0,0,0,7.41,2.83,11.24,11.24,0,0,0,4.21-.79.4.4,0,0,0,.23-.51A.4.4,0,0,0,18.42,14.29Z" />
                <path d="M19.74,13.2a.4.4,0,0,0-.53-.17,7.26,7.26,0,0,1-2.88.58,7.06,7.06,0,0,1-5-1.83.37.37,0,0,0-.53,0,.38.38,0,0,0,0,.54,7.84,7.84,0,0,0,5.53,2,8,8,0,0,0,3.21-.64A.4.4,0,0,0,19.74,13.2Z" />
                <path d="M20.69,11.75a.4.4,0,0,0-.53-.17,4.93,4.93,0,0,1-2,.39,4.78,4.78,0,0,1-3.39-1.24.37.37,0,0,0-.53,0,.38.38,0,0,0,0,.54,5.53,5.53,0,0,0,3.92,1.44,5.66,5.66,0,0,0,2.33-.45A.4.4,0,0,0,20.69,11.75Z" />
              </svg>
            </Box>
          </Box>

          <Typography variant="h5" component="h2" fontWeight={600} gutterBottom>
            Connect ReviewsMaxx with Amazon
          </Typography>
          
          <Typography variant="body1" color="text.secondary" sx={{ mb: 4 }}>
            Authorize ReviewsMaxx with your Amazon account to automate review requests,
            boost sales, and take your business to the next level.
          </Typography>

          <Paper 
            elevation={0} 
            sx={{ 
              p: 2, 
              bgcolor: 'primary.50',
              mb: 4,
              display: 'flex',
              alignItems: 'center',
              gap: 2
            }}
          >
            <SecurityIcon color="primary" />
            <Box sx={{ textAlign: 'left' }}>
              <Typography variant="subtitle2" color="primary" fontWeight={600}>
                Security Guarantee
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Your information is securely protected and never shared with third parties,
                ensuring complete confidentiality and peace of mind.
              </Typography>
            </Box>
          </Paper>

          {error && (
            <Alert 
              severity="error" 
              sx={{ mb: 3 }}
            >
              {error}
            </Alert>
          )}

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Button
              variant="contained"
              size="large"
              onClick={onConnect}
              sx={{ 
                py: 1.5,
                fontSize: '1rem',
                fontWeight: 500
              }}
            >
              Connect Your Seller Central Account
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </StyledDialog>
  );
};

export default AmazonConnectionModal; 