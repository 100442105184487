import React from 'react';
import { Box, Card, Typography, useTheme } from '@mui/material';
import { TrendingUp, TrendingDown } from '@mui/icons-material';

interface MetricCardProps {
  title: string;
  value: string | number;
  trend?: number;
  trendLabel?: string;
  isPercentage?: boolean;
  isCurrency?: boolean;
}

const MetricCard: React.FC<MetricCardProps> = ({
  title,
  value,
  trend,
  trendLabel,
  isPercentage,
  isCurrency
}) => {
  const theme = useTheme();

  const formatValue = (val: string | number) => {
    if (typeof val === 'string') return val;
    if (isCurrency) return `$${val.toLocaleString()}`;
    if (isPercentage) return `${val}%`;
    return val.toLocaleString();
  };

  const getTrendColor = (trendValue: number) => {
    if (trendValue > 0) return theme.palette.success.main;
    if (trendValue < 0) return theme.palette.error.main;
    return theme.palette.text.secondary;
  };

  return (
    <Card
      sx={{
        p: 2.5,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        bgcolor: 'background.paper',
        border: '1px solid',
        borderColor: 'divider',
      }}
    >
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{ mb: 1, fontWeight: 500 }}
      >
        {title}
      </Typography>

      <Box>
        <Typography
          variant="h4"
          component="div"
          sx={{
            mb: 1,
            fontWeight: 600,
            color: 'text.primary',
          }}
        >
          {formatValue(value)}
        </Typography>

        {trend !== undefined && (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            {trend > 0 ? (
              <TrendingUp sx={{ color: getTrendColor(trend) }} />
            ) : (
              <TrendingDown sx={{ color: getTrendColor(trend) }} />
            )}
            <Typography
              variant="body2"
              sx={{
                color: getTrendColor(trend),
                fontWeight: 500,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {Math.abs(trend)}%
              {trendLabel && (
                <Typography
                  component="span"
                  variant="body2"
                  color="text.secondary"
                  sx={{ ml: 0.5 }}
                >
                  {trendLabel}
                </Typography>
              )}
            </Typography>
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default MetricCard; 