import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  useTheme,
  Alert,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Link,
  Divider,
} from '@mui/material';
import {
  Visibility,
  VisibilityOff,
  Email as EmailIcon,
  Lock as LockIcon,
  Star as StarIcon,
  Google,
} from '@mui/icons-material';
import { AuthContext } from '../context/AuthContext';
import styled from '@emotion/styled';

const goldColor = '#FFB800';
const darkBlue = '#1a1f2b';
const dotColor = 'rgba(255, 255, 255, 0.05)';

const GoldButton = styled(Button)({
  backgroundColor: darkBlue,
  color: 'white',
  '&:hover': {
    backgroundColor: '#2a3241',
  },
  padding: '12px 24px',
  fontSize: '1.1rem',
  fontWeight: 600,
});

const LogoText = styled(Typography)({
  color: darkBlue,
  fontWeight: 800,
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

const DottedBackground = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundImage: `
    radial-gradient(${dotColor} 1px, transparent 1px),
    radial-gradient(${dotColor} 1px, transparent 1px)
  `,
  backgroundSize: '20px 20px',
  backgroundPosition: '0 0, 10px 10px',
  opacity: 0.5,
});

const WaveTransition = styled(Box)({
  position: 'absolute',
  left: -2,
  top: 0,
  height: '100%',
  width: '80px',
  zIndex: 2,
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    background: darkBlue,
    clipPath: `
      path('M80,0 
      C60,20 30,40 40,50 
      C50,60 70,60 60,70 
      C50,80 30,80 40,90 
      C50,100 80,100 80,100 
      L0,100 L0,0 Z')
    `,
    boxShadow: '-10px 0 20px rgba(0,0,0,0.1)',
  }
});

const Auth: React.FC = () => {
  const navigate = useNavigate();
  const { login, register } = useContext(AuthContext);
  const [isLogin, setIsLogin] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    setMessage('');

    try {
      if (isLogin) {
        const result = await login(email, password);
        if (result.error) {
          setError(result.error.message || 'Invalid login credentials');
          return; // Don't navigate if there's an error
        }
        navigate('/dashboard');
      } else {
        const result = await register(email, password);
        if (result.error) {
          setError(result.error.message || 'Registration failed');
          return;
        }
        setMessage('Registration successful! Please check your email to verify your account.');
        setIsLogin(true);
        setEmail('');
        setPassword('');
      }
    } catch (err: any) {
      console.error('Authentication error:', err);
      setError(err.message || 'An error occurred during authentication');
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);
  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value);

  return (
    <Box sx={{ 
      minHeight: '100vh', 
      display: 'flex',
      position: 'relative',
      overflow: 'hidden',
    }}>
      {/* Left side - Marketing Content */}
      <Box
        sx={{
          display: { xs: 'none', md: 'flex' },
          flexDirection: 'column',
          width: '30%',
          position: 'relative',
          background: darkBlue,
          color: 'white',
          zIndex: 1,
          overflow: 'hidden',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <DottedBackground />
        <Box
          sx={{
            position: 'relative',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            px: 3,
            zIndex: 3,
          }}
        >
          <Stack spacing={4} sx={{ width: '100%' }}>
            <Box>
              <Typography variant="h3" sx={{ 
                mb: 3, 
                fontWeight: 700,
                textAlign: 'center'
              }}>
                Turn Happy Customers into
                <Box component="span" sx={{ display: 'block' }}>
                  5-Star Reviews
                  <Box component="span" sx={{ ml: 2, color: goldColor }}>
                    {'★'.repeat(5)}
                  </Box>
                </Box>
              </Typography>
            </Box>

            {[
              {
                icon: '⭐',
                title: 'Smart Review Requests',
                description: 'Automatically request reviews from satisfied Amazon customers at the perfect time'
              },
              {
                icon: '📈',
                title: 'Boost Your Ratings',
                description: 'Increase your product visibility with more authentic Amazon reviews'
              },
              {
                icon: '✓',
                title: 'Amazon TOS Compliant',
                description: "Always following Amazon's guidelines for review requests"
              }
            ].map((feature) => (
              <Box key={feature.title} sx={{ display: 'flex', alignItems: 'flex-start', gap: 2 }}>
                <Typography sx={{ fontSize: '24px' }}>{feature.icon}</Typography>
                <Box sx={{ flex: 1 }}>
                  <Typography variant="h6" sx={{ fontWeight: 600, mb: 0.5, color: goldColor }}>
                    {feature.title}
                  </Typography>
                  <Typography variant="body2" sx={{ opacity: 0.8 }}>
                    {feature.description}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Stack>
        </Box>
      </Box>

      {/* Right side - Auth Form */}
      <Box
        sx={{
          width: { xs: '100%', md: '70%' },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          minHeight: '100vh',
          background: 'background.paper',
          position: 'relative',
          zIndex: 2,
          p: 0,
        }}
      >
        {/* Center content wrapper */}
        <Box sx={{ 
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <Box sx={{ 
            maxWidth: '420px',
            width: '100%',
            textAlign: 'center',
          }}>
            <Box sx={{ mb: 3, display: 'flex', justifyContent: 'center' }}>
              <StarIcon sx={{ 
                color: '#EAB308',
                fontSize: '3.5rem',
                strokeWidth: 2,
                stroke: 'currentColor',
                fill: 'none'
              }} />
            </Box>

            <Typography
              variant="h5"
              component="h2"
              sx={{
                fontWeight: 600,
                color: 'text.primary',
                mb: 4,
                fontSize: '1.5rem',
              }}
            >
              Log in to ReviewsMaxx
            </Typography>

            {/* SSO Buttons */}
            <Stack 
              direction="row"
              spacing={2} 
              sx={{ mb: 4 }}
            >
              <Button
                fullWidth
                variant="outlined"
                startIcon={<Google />}
                sx={{
                  py: 1.5,
                  borderRadius: 1,
                  borderColor: 'grey.300',
                  color: 'text.primary',
                  flex: 1,
                  '&:hover': {
                    borderColor: 'grey.400',
                    bgcolor: 'grey.50',
                  }
                }}
              >
                Google
              </Button>
              <Button
                fullWidth
                variant="outlined"
                sx={{
                  py: 1.5,
                  borderRadius: 1,
                  borderColor: 'grey.300',
                  color: 'text.primary',
                  flex: 1,
                  '&:hover': {
                    borderColor: 'grey.400',
                    bgcolor: 'grey.50',
                  }
                }}
              >
                Amazon
              </Button>
              <Button
                fullWidth
                variant="outlined"
                sx={{
                  py: 1.5,
                  borderRadius: 1,
                  borderColor: 'grey.300',
                  color: 'text.primary',
                  flex: 1,
                  '&:hover': {
                    borderColor: 'grey.400',
                    bgcolor: 'grey.50',
                  }
                }}
              >
                SSO
              </Button>
            </Stack>

            {/* Divider */}
            <Box sx={{ mb: 4, display: 'flex', alignItems: 'center' }}>
              <Divider sx={{ flex: 1 }} />
              <Typography 
                variant="body2" 
                color="text.secondary" 
                sx={{ px: 2 }}
              >
                or
              </Typography>
              <Divider sx={{ flex: 1 }} />
            </Box>

            {error && <Alert severity="error" sx={{ mb: 3 }}>{error}</Alert>}
            {message && <Alert severity="success" sx={{ mb: 3 }}>{message}</Alert>}

            <form onSubmit={handleSubmit}>
              <Stack spacing={2.5}>
                <TextField
                  fullWidth
                  placeholder="Email"
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  required
                  variant="outlined"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      bgcolor: 'background.default',
                      borderRadius: 1,
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EmailIcon color="action" />
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  fullWidth
                  placeholder="Password"
                  type={showPassword ? 'text' : 'password'}
                  value={password}
                  onChange={handlePasswordChange}
                  required
                  variant="outlined"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      bgcolor: 'background.default',
                      borderRadius: 1,
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon color="action" />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                          size="small"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <GoldButton
                  type="submit"
                  variant="contained"
                  fullWidth
                  sx={{
                    py: 1.5,
                    mt: 1,
                    borderRadius: 1,
                    fontSize: '0.95rem',
                  }}
                >
                  {isLogin ? 'Sign In' : 'Create Account'}
                </GoldButton>
              </Stack>
            </form>

            <Box sx={{ mt: 4, textAlign: 'center' }}>
              <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{ mb: 1 }}
              >
                {isLogin
                  ? "Don't have an account?"
                  : 'Already have an account?'}
                <Button
                  onClick={() => {
                    setIsLogin(!isLogin);
                    setError('');
                    setMessage('');
                  }}
                  variant="text"
                  sx={{ 
                    textTransform: 'none',
                    fontWeight: 500,
                    ml: 1,
                  }}
                >
                  {isLogin ? 'Sign up' : 'Sign in'}
                </Button>
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* Bottom text */}
        <Box sx={{ 
          width: '100%',
          textAlign: 'center',
          py: 3,
          borderTop: '1px solid',
          borderColor: 'white',
        }}>
          <Typography 
            variant="body2" 
            color="text.secondary"
            sx={{ 
              fontSize: '0.8rem',
            }}
          >
            By clicking Log In, you agree to our{' '}
            <Link href="#" color="primary" sx={{ textDecoration: 'none' }}>
              Terms of Service
            </Link>
            {' '}and{' '}
            <Link href="#" color="primary" sx={{ textDecoration: 'none' }}>
              Privacy Policy
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Auth;