import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

const BackgroundPattern = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: -1,
  backgroundColor: theme.palette.background.default,
  backgroundImage: `
    radial-gradient(${theme.palette.grey[300]} 1.5px, transparent 1.5px)
  `,
  backgroundSize: '24px 24px',
  backgroundPosition: '0 0',
  opacity: 0.7,
}));

export default BackgroundPattern; 