import React, { createContext, useState, useEffect, ReactNode } from 'react';
import { User } from '../types';
import { supabase } from '../config/supabase';
import { Session } from '@supabase/supabase-js';
import { apiService } from '../services/api.service';

export const AuthContext = createContext<{
  user: User | null;
  authenticated: boolean;
  loading: boolean;
  setAuthenticated: (value: boolean) => void;
  login: (email: string, password: string) => Promise<{ error?: any }>;
  register: (email: string, password: string) => Promise<{ error?: any }>;
  logout: () => Promise<void>;
  needsAmazonConnection: boolean;
  setNeedsAmazonConnection: (value: boolean) => void;
}>({
  user: null,
  authenticated: false,
  loading: true,
  setAuthenticated: () => {},
  login: async () => ({}),
  register: async () => ({}),
  logout: async () => {},
  needsAmazonConnection: false,
  setNeedsAmazonConnection: () => {}
});

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [needsAmazonConnection, setNeedsAmazonConnection] = useState(false);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    let mounted = true;
    console.log('AuthProvider: Starting initialization');
    
    const initAuth = async () => {
      try {
        console.log('AuthProvider: Getting session');
        const { data: { session }, error: sessionError } = await supabase.auth.getSession();
        
        if (sessionError) {
          console.error('Session error:', sessionError);
          throw sessionError;
        }

        console.log('AuthProvider: Session details:', {
          hasSession: !!session,
          userId: session?.user?.id,
          email: session?.user?.email,
          hasAccessToken: !!session?.access_token,
          expiresAt: session?.expires_at
        });
        
        if (session && mounted) {
          await handleSession(session);
        } else {
          console.log('AuthProvider: No session found, setting initial state');
          setUser(null);
          setAuthenticated(false);
          setNeedsAmazonConnection(false);
        }
      } catch (error) {
        console.error('Auth initialization error:', error);
        setUser(null);
        setAuthenticated(false);
        setNeedsAmazonConnection(false);
      } finally {
        if (mounted) {
          setLoading(false);
          setInitialized(true);
        }
      }
    };

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange(async (event, session) => {
      console.log('AuthProvider: Auth state changed:', { event, userId: session?.user?.id });
      
      if (!mounted || !initialized) return;

      switch (event) {
        case 'SIGNED_IN':
          if (session) await handleSession(session);
          break;
        case 'SIGNED_OUT':
          setUser(null);
          setAuthenticated(false);
          setNeedsAmazonConnection(false);
          break;
        case 'TOKEN_REFRESHED':
          if (session) await handleSession(session);
          break;
        case 'USER_UPDATED':
          if (session) await handleSession(session);
          break;
      }
    });

    initAuth();

    return () => {
      mounted = false;
      subscription.unsubscribe();
    };
  }, [initialized]);

  const clearAuthState = async () => {
    setLoading(true);
    try {
      console.log('AuthProvider: Clearing auth state');
      await supabase.auth.signOut();
      setUser(null);
      setAuthenticated(false);
      setNeedsAmazonConnection(false);
    } catch (error) {
      console.error('Error clearing auth state:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSession = async (session: Session) => {
    try {
      console.log('AuthProvider: Handling session for user:', session.user.email);
      
      if (session.expires_at && session.expires_at * 1000 < Date.now()) {
        console.log('AuthProvider: Session expired during handling');
        await clearAuthState();
        return;
      }

      try {
        const response = await apiService.getCurrentUser();
        const userData = response.data;
        
        if (!userData) {
          throw new Error('No user data received');
        }

        console.log('AuthProvider: Got user data:', userData);
        setUser(userData);
        setAuthenticated(true);
        setNeedsAmazonConnection(!userData.isAmazonConnected);
      } catch (error: any) {
        console.error('Error getting user data:', error);
        
        if (error.response?.status === 401 || error.response?.status === 403) {
          console.log('AuthProvider: Auth error, signing out user');
          await clearAuthState();
          return;
        }
        
        if (error.message.includes('Unable to connect') || 
            error.message.includes('Network error') ||
            window.location.search.includes('error') || 
            window.location.search.includes('code')) {
          
          setUser({
            id: session.user.id,
            email: session.user.email!,
            isAmazonConnected: false
          });
          setAuthenticated(true);
          setNeedsAmazonConnection(true);
          
          if (window.location.search) {
            window.history.replaceState({}, '', window.location.pathname);
          }
        } else {
          console.error('Non-recoverable error, clearing auth state:', error);
          await clearAuthState();
        }
      }
    } catch (error) {
      console.error('Error in handleSession:', error);
      await clearAuthState();
    } finally {
      setLoading(false);
    }
  };

  const login = async (email: string, password: string) => {
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) {
        return { error };
      }

      if (data.user) {
        setUser({
          id: data.user.id,
          email: data.user.email!,
          isAmazonConnected: false
        });
      }
      
      return {};
    } catch (error) {
      return { error };
    }
  };

  const register = async (email: string, password: string) => {
    try {
      const { data, error } = await supabase.auth.signUp({
        email,
        password,
      });

      if (error) {
        return { error };
      }

      return {};
    } catch (error) {
      return { error };
    }
  };

  const logout = async () => {
    try {
      setLoading(true);
      console.log('AuthProvider: Logging out');
      await clearAuthState();
    } catch (error) {
      console.error('Logout error:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const value = {
    user,
    authenticated,
    loading,
    setAuthenticated,
    login,
    register,
    logout,
    needsAmazonConnection,
    setNeedsAmazonConnection,
  };

  console.log('AuthProvider: Current state:', { 
    authenticated, 
    loading, 
    hasUser: !!user,
    needsAmazonConnection 
  });

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}; 