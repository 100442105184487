import React from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';

const LoadingScreen: React.FC = () => {
  return (
    <Box
      sx={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: 'background.default',
        zIndex: 9999,
      }}
    >
      <CircularProgress size={48} />
      <Typography
        variant="h6"
        sx={{
          mt: 2,
          color: 'text.secondary',
          fontWeight: 500
        }}
      >
        Loading ReviewsMaxx...
      </Typography>
    </Box>
  );
};

export default LoadingScreen; 