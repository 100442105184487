import React, { useContext, ReactNode, useEffect, useCallback, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Auth from './pages/Auth';
import Dashboard from './pages/Dashboard';
import { AuthProvider, AuthContext } from './context/AuthContext';
import MainLayout from './components/Layout/MainLayout';
import { theme } from './theme';
import Orders from './pages/Orders';
import Profile from './pages/Profile';
import AmazonConnectionModal from './components/AmazonConnectionModal';
import InitialSyncModal from './components/InitialSyncModal';
import { apiService } from './services/api.service';
import LoadingScreen from './components/LoadingScreen';

interface ProtectedRouteProps {
  children: ReactNode;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { authenticated, loading } = useContext(AuthContext);
  const navigate = useNavigate();
  
  useEffect(() => {
    if (!loading && !authenticated) {
      navigate('/auth', { replace: true });
    }
  }, [loading, authenticated, navigate]);
  
  if (loading) {
    return <LoadingScreen />;
  }
  
  return authenticated ? <>{children}</> : null;
};

const AppRoutes = () => {
  const { authenticated, loading } = useContext(AuthContext);
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/auth', { replace: true });
    } catch (error) {
      console.error('Logout error:', error);
      // Force navigation to auth on error
      navigate('/auth', { replace: true });
    }
  };

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Routes>
      <Route 
        path="/" 
        element={
          authenticated ? (
            <Navigate to="/dashboard" replace />
          ) : (
            <Navigate to="/auth" replace />
          )
        } 
      />
      <Route 
        path="/auth" 
        element={
          authenticated ? (
            <Navigate to="/dashboard" replace />
          ) : (
            <Auth />
          )
        } 
      />
      <Route
        path="/*"
        element={
          <ProtectedRoute>
            <MainLayout onLogout={handleLogout}>
              <Routes>
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="orders" element={<Orders />} />
                <Route path="profile" element={<Profile />} />
                <Route path="settings" element={<div>Settings Page</div>} />
              </Routes>
            </MainLayout>
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppContent />
      </Router>
    </AuthProvider>
  );
}

const AppContent: React.FC = () => {
  const { loading, authenticated, needsAmazonConnection, setNeedsAmazonConnection } = useContext(AuthContext);
  const [amazonError, setAmazonError] = useState<string | undefined>();
  const [showInitialSync, setShowInitialSync] = useState(false);
  const navigate = useNavigate();
  
  const handleConnectAmazon = async () => {
    try {
      setAmazonError(undefined);
      const response = await apiService.getAmazonAuthUrl();
      
      // Open in a popup window
      const width = 600;
      const height = 700;
      const left = window.screenX + (window.outerWidth - width) / 2;
      const top = window.screenY + (window.outerHeight - height) / 2;
      
      const popup = window.open(
        response.data.url,
        'Amazon SSO',
        `width=${width},height=${height},left=${left},top=${top},toolbar=0,location=0,status=0,menubar=0,scrollbars=1,resizable=1`
      );

      // Listen for messages from the popup
      const handleMessage = async (event: MessageEvent) => {
        if (event.data.type === 'AMAZON_AUTH_COMPLETE') {
          window.removeEventListener('message', handleMessage);
          
          if (event.data.success) {
            try {
              // Immediately close the connection modal
              setNeedsAmazonConnection(false);
              
              // Show the initial sync modal
              setShowInitialSync(true);
              
              // Fetch updated user data
              const response = await apiService.getCurrentUser();
              const userData = response.data;
              
              if (!userData) {
                throw new Error('No user data received');
              }

              // Trigger initial order sync
              await apiService.syncOrders();
            } catch (error) {
              console.error('Failed to refresh user data:', error);
              setAmazonError('Connection succeeded but failed to refresh user data. Please refresh the page.');
            }
          } else if (event.data.error) {
            setAmazonError('Failed to connect to Amazon: ' + event.data.error);
          }
        }
      };

      window.addEventListener('message', handleMessage);
    } catch (err: any) {
      console.error('Failed to start Amazon connection process:', err);
      setAmazonError('Failed to connect to Amazon. Please try again.');
    }
  };

  // Check for Amazon auth error in URL
  useEffect(() => {
    if (window.location.search.includes('error')) {
      setAmazonError('Amazon connection failed. Please try again.');
      // Clear error params from URL
      window.history.replaceState({}, '', window.location.pathname);
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          <AppRoutes />
          <AmazonConnectionModal
            open={authenticated && needsAmazonConnection}
            onConnect={handleConnectAmazon}
            activeStep={2}
            error={amazonError}
          />
          <InitialSyncModal
            open={showInitialSync}
          />
        </>
      )}
    </ThemeProvider>
  );
};

export default App;