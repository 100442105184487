import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Paper,
  Typography,
  Box,
  Grid,
  Alert,
  Chip,
  Divider,
  Card,
  CardContent,
  Button,
  Avatar,
  Link,
} from '@mui/material';
import {
  Person,
  Store,
  LinkOff,
  Link as LinkIcon,
  Email,
  CalendarToday,
  Business,
} from '@mui/icons-material';
import { apiService } from '../services/api.service';
import { User } from '../types';
import { formatDate } from '../utils';

const Profile: React.FC = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const controller = new AbortController();
    
    const loadUserData = async () => {
      try {
        const response = await apiService.getCurrentUser({ signal: controller.signal });
        setUser(response.data);
      } catch (err: any) {
        if (!(err instanceof Error && err.name === 'AbortError')) {
          setError('Failed to fetch user data');
          if (err.response?.status === 401) {
            navigate('/auth', { replace: true });
          }
        }
      }
    };

    loadUserData();

    return () => controller.abort();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const connectAmazon = async () => {
    try {
      const response = await apiService.getAmazonAuthUrl();
      window.location.href = response.data.url;
    } catch (err: any) {
      setError('Failed to start Amazon connection process: ' + (err.message || 'Unknown error'));
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        {message && <Alert severity="success" sx={{ mb: 2 }}>{message}</Alert>}

        <Grid container spacing={3}>
          {/* Profile Overview Card */}
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 3 }}>
                  <Avatar
                    sx={{
                      width: 100,
                      height: 100,
                      bgcolor: 'primary.main',
                      mb: 2,
                      fontSize: '2.5rem',
                    }}
                  >
                    {user?.email?.charAt(0).toUpperCase()}
                  </Avatar>
                  <Typography variant="h5" gutterBottom>
                    {user?.email}
                  </Typography>
                  <Chip
                    label={user?.isAmazonConnected ? "Amazon Connected" : "Amazon Not Connected"}
                    color={user?.isAmazonConnected ? "success" : "error"}
                    icon={user?.isAmazonConnected ? <Store /> : <LinkOff />}
                  />
                </Box>
                
                {!user?.isAmazonConnected && (
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    startIcon={<LinkIcon />}
                    onClick={connectAmazon}
                  >
                    Connect Amazon Account
                  </Button>
                )}
              </CardContent>
            </Card>
          </Grid>

          {/* Account Details Card */}
          <Grid item xs={12} md={8}>
            <Card>
              <CardContent>
                <Typography variant="h5" gutterBottom sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Person />
                  Account Details
                </Typography>
                <Divider sx={{ my: 2 }} />
                
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                      <Email color="action" />
                      <Box>
                        <Typography variant="body2" color="textSecondary">
                          Email Address
                        </Typography>
                        <Typography>{user?.email}</Typography>
                      </Box>
                    </Box>
                  </Grid>

                  {user?.isAmazonConnected && (
                    <>
                      <Grid item xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                          <Store color="action" />
                          <Box>
                            <Typography variant="body2" color="textSecondary">
                              Amazon Seller ID
                            </Typography>
                            <Typography>{user?.amazonSellerId || 'N/A'}</Typography>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item xs={12}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
                          <Business color="action" />
                          <Box>
                            <Typography variant="body2" color="textSecondary">
                              Marketplace
                            </Typography>
                            <Typography>Amazon.com</Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                      <CalendarToday color="action" />
                      <Box>
                        <Typography variant="body2" color="textSecondary">
                          Member Since
                        </Typography>
                        <Typography>{user?.createdAt ? formatDate(user.createdAt) : 'N/A'}</Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>

                <Divider sx={{ my: 3 }} />

                <Typography variant="body2" color="textSecondary" sx={{ mb: 2 }}>
                  Need help? Contact our support team at{' '}
                  <Link href="mailto:support@reviewsmaxx.com">
                    support@reviewsmaxx.com
                  </Link>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Profile; 